
import { get_time } from './components/customer_utils.js';

frappe.provide('frappe.Customer');

/** Spawns a chat widget on any web page */
frappe.Counter = class {
  constructor() {
    this.setup_app();
  }

  /** Create all the required elements for chat widget */
  create_app(days=0) {



    if (this.is_desk === true) {
      // $('header.navbar > .container > .navbar-collapse > ul').prepend(
      //   navbar_icon_html
      // );


      // this.show_timer(1);
      // this.show_timer(20 / 86400);
      $("body").addClass("full-width");
      this.show_timer(days);

    }

    this.setup_events();
  }

  /** Load dependencies and fetch the settings */
  async setup_app() {
    try {
      const res = await get_time();
      this.is_desk = 'desk' in frappe;

   

      if (!this.is_desk) {
        return;
      }

      this.create_app(res);

      frappe.Chat.settings = {};


    } catch (error) {
      console.error(error);
    }
  }

 

 

  show_timer(days) {
    const me = this;

    // تحويل الأيام إلى ثواني
    const timer = days * 24 * 60 * 60;

    // حساب وقت الهدف
    const target_time = moment().add(timer, 'seconds');

    // HTML للنص التمهيدي والتوقيت
    const timer_html = `
      <div class="timer-container" style="font-weight:bold; margin:10px 0; color:#545454; font-size:10px; display:flex; align-items:center;">
        <div class="intro-text" style="margin-right:10px; margin-left:10px;">
          ${__('You have {0} days left', [days])}
        </div>
        <div class="stopwatch" style="display:inline-block; vertical-align:middle;">
          <span class="hours">00</span>
          <span class="colon">:</span>
          <span class="minutes">00</span>
          <span class="colon">:</span>
          <span class="seconds">00</span>
        </div>
      </div>
  `;

    // إضافة HTML إلى الصفحة
    $('.standard-actions').append(timer_html);

    const interval = setInterval(function () {
      // حساب الوقت المتبقي
      const now = moment();
      const remaining = moment.duration(target_time.diff(now));

      if (remaining.asSeconds() <= 0) {
        // إيقاف المؤقت عند انتهاء الوقت
        clearInterval(interval);
        me.interval_id = null;
        $(".hours").text("00");
        $(".minutes").text("00");
        $(".seconds").text("00");
        $(".intro-text").text(__('باقتك الحالية انتهت يرجى تجديد الباقة'));
        frappe.msgprint({
          title: __('انتهى الوقت الخاص بكم'),
          indicator: 'red',
          message: __('باقتك الحالية انتهت يرجى تجديد الباقة')
        });
        
        setTimeout(() => {
          console.log("Forcing full page reload...");
          me.hide_timer();
        }, 1000);

        return;
      }

      // تحديث القيم الزمنية
      const remaining_days = Math.floor(remaining.asDays());
      const hours = Math.floor(remaining.hours());
      const minutes = Math.floor(remaining.minutes());
      const seconds = Math.floor(remaining.seconds());

      // تحديث النص التمهيدي
      $(".intro-text").text(__('You have {0} days left', [remaining_days]));

      // تحديث المؤقت
      $(".hours").text(hours.toString().padStart(2, '0'));
      $(".minutes").text(minutes.toString().padStart(2, '0'));
      $(".seconds").text(seconds.toString().padStart(2, '0'));
    }, 1000);

    // حفظ معرف التكرار لإيقافه لاحقًا
    this.interval_id = interval;
}
 

  hide_timer() {
    // Remove the timer UI and clear the interval
    // frm.toolbar.page.inner_toolbar.find(".stopwatch").remove();
    if (this.interval_id) {
      clearInterval(this.interval_id);
      this.interval_id = null;

    }
  }
  
  

  setup_events() {
    const me = this;


    // $(document).mouseup(function (e) {
    //   if (me.should_close(e) && me.is_open === true) {
    //     me.chat_bubble.change_bubble();
    //   }
    // });
  }
};

$(function () {
  new frappe.Counter();
});
