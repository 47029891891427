

async function get_time() {
    const res = await frappe.call({
        type: 'GET',
        method: 'customer.api.config.get_time',
        //   args: {
        //     token: token,
        //   },
    });
    return await res.message;
}
async function stop_plan() {
    const res = await frappe.call({
        type: 'GET',
        method: 'customer.utils.functions.insert_domains',
          args: {
            active_domains: ["Subscription"],
          },
    });
    return await res.message;
}
export {
    get_time
 
};
  